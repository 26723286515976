.badge {
    top: 10px;
    right: 20px;
    font-size: 17px;
}
.cards{
    border: 2px solid #0b5ed7;
    border-radius: 10px;
}
.img{
    border-radius: 10px 10px 0 0;
}