.input {
    width: 40%;
    border-radius: 8px;
    border: 2px solid #0b5ed7;
    padding: 10px 15 px;
    box-shadow: 1px 3px 9px gray;

}
.btn{
    box-shadow: 1px 3px 9px gray;

}

@media (max-width: 576px) {
    .input {
      width: 80%;
    }
  }