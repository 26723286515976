@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ubuntu {
  font-family: "Ubuntu" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Cards_badge__393U-{top:10px;right:20px;font-size:17px}.Cards_cards__3lktC{border:2px solid #0b5ed7;border-radius:10px}.Cards_img__1TFDR{border-radius:10px 10px 0 0}
.Search_input__2zEIH{width:40%;border-radius:8px;border:2px solid #0b5ed7;padding:10px 15 px;box-shadow:1px 3px 9px gray}.Search_btn__fnGFA{box-shadow:1px 3px 9px gray}@media(max-width: 576px){.Search_input__2zEIH{width:80%}}
.active {
    border-bottom: 3px solid #0b5ed7;
    font-weight: bold;
    color:#0b5ed7 !important;
}
